import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IFAQ, IGuideline, IPrizes, IRules, ITerms} from "../../types";
import {IPrivacyPolicy} from "../../types/jsons";

export type IHelpReducer = IGuidelineReducer &
	IPrizesReducer &
	ITermsReducer &
	IFAQReducer &
	ITranslations &
	IFormGuideReducer &
	IRulesReducer;

export interface IRulesReducer {
	rules: IRules[];
}

export interface IGuidelineReducer {
	guidelines: IGuideline[];
}

export interface IPrizesReducer {
	prizes: IPrizes[];
}

export interface ITermsReducer {
	terms: ITerms[];
}

export interface IFAQReducer {
	faqs: IFAQ[];
}

export interface ITranslations {
	translations: {string: string} | {};
}

export interface IPrivacyPolicyReducer {
	privacy_policy: IPrivacyPolicy[];
}

export interface IFormGuide {
	home_squad_id: number;
	away_squad_id: number;
	ladder: {
		[squad_id: string]: {
			wins: number;
			draws: number;
			losses: number;
			points_per_game: number;
			points_allowed_per_game: number;
			last5: {
				wins: number;
				losses: number;
				draws: number;
			};
			streak: string;
		};
	};
}

export interface IFormGuideReducer {
	form_guide: {
		by_id: {[match_id: string]: IFormGuide};
	};
}

export interface IFormGuidePayload {
	[match_id: string]: IFormGuide;
}

const initialState: IHelpReducer = {
	guidelines: [],
	terms: [],
	faqs: [],
	prizes: [],
	rules: [],
	translations: {},
	form_guide: {
		by_id: {},
	},
};

export const jsons = createReducer<IHelpReducer>({}, initialState)
	.on(
		actions.fetchFAQSSuccess,
		(state: IHelpReducer, payload: IFAQReducer) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.fetchPrizesSuccess,
		(state: IHelpReducer, payload: IPrizesReducer) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.fetchRulesSuccess,
		(state: IHelpReducer, payload: IRulesReducer) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.fetchGuidelinesSuccess,
		(state: IHelpReducer, payload: IGuidelineReducer) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.fetchPrivacyPolicySuccess,
		(state: IHelpReducer, payload: IPrivacyPolicyReducer) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.fetchTranslationsJSONSuccess,
		(state: IHelpReducer, payload: ITranslations) => ({
			...state,
			translations: payload,
		})
	)
	.on(
		actions.fetchFormGuideSuccess,
		(state: IHelpReducer, payload: IFormGuidePayload) => ({
			...state,
			form_guide: {
				by_id: {
					...state.form_guide.by_id,
					...payload,
				},
			},
		})
	);
