import {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import Api from "../../utils/Api";
import * as actions from "../../actions";
// import { filter } from 'lodash';
import {ISagaAction} from "../../types";

export const fetchPrizesJSON = function* (): SagaIterator {
	const {jsons} = yield select();
	try {
		if (!jsons.prizes.length) {
			const prizes = yield call(Api.JSON.prizes);

			yield put(actions.fetchPrizesSuccess({prizes: prizes.items}));
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchGuidelinesJSON = function* (): SagaIterator {
	const {jsons} = yield select();
	try {
		if (!jsons.guidelines.length) {
			const guidelines = yield call(Api.JSON.guidelines);
			yield put(actions.fetchGuidelinesSuccess({guidelines}));
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchRulesJSON = function* (): SagaIterator {
	const {jsons} = yield select();
	try {
		if (!jsons.rules.length) {
			const rules = yield call(Api.JSON.rules);

			yield put(actions.fetchRulesSuccess({rules}));
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchPrivacyPolicyJSON = function* (): SagaIterator {
	const {jsons} = yield select();
	try {
		if (!jsons.rules.length) {
			const response = yield call(Api.JSON.privacy_policy);

			yield put(
				actions.fetchPrivacyPolicySuccess({privacy_policy: response})
			);
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchFAQSJSON = function* (): SagaIterator {
	const {jsons} = yield select();
	try {
		if (!jsons.faqs.length) {
			const faqs = yield call(Api.JSON.faq);

			// const format_faqs = faqs.categories.map((category: any) => {
			// 	const items = filter(faqs.items, { category_id: category.id });
			// 	return {
			// 		...category,
			// 		items
			// 	}
			// });
			// console.log('--=--format_faqs', format_faqs);
			yield put(actions.fetchFAQSSuccess({faqs}));
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchFormGuideJSON = function* ({
	payload,
}: ISagaAction<{id: number}>): SagaIterator {
	try {
		const data = yield call(Api.JSON.form_quide, payload);

		yield put(actions.fetchFormGuideSuccess({[payload.id]: {...data}}));
	} catch (e) {
		console.log(e);
	}
};

export const fetchRoundStatsJSON = function* ({
	payload,
}: ISagaAction<{round: number}>): SagaIterator {
	try {
		const responce = yield call(Api.JSON.round_stats, payload);

		if (responce) {
			yield put(actions.fetchRoundStatsSuccess(responce));
		}
	} catch (e) {
		console.log(e);
	}
};

export const fetchTranslationsJSONSaga = function* ({
	payload,
}: ISagaAction<{lang: string}>): SagaIterator {
	try {
		const responce = yield call(Api.JSON.langs, payload);

		yield put(actions.fetchTranslationsJSONSuccess(responce));
	} catch (e) {
		console.log(e);
	}
};
