import * as _ from "lodash";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import {
	IGigyaOnLogin,
	ISagaAction,
	IUserCreate,
	IUserRegisterForGame,
	IUserUpdate,
} from "../../types";
import {clearUserFromStorage, saveUserToStorage} from "../../utils/user";
import {Api, ApiErrors} from "../../utils";
import * as actions from "../../actions";
import {responseCheck} from "../errors";
import * as Sentry from "@sentry/browser";
import {isLoggedIn} from "../../selectors";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

interface IResponse {
	errors: Array<{
		text: string;
		code: number;
	}>;
	success?: boolean;
}

const errorsCheck = ({errors, success}: IResponse) => {
	const is_error = !_.isEmpty(errors);

	if (is_error && !success) {
		throw new ApiErrors(errors[0].text, errors[0].code);
	}
};

// tslint:disable:cyclomatic-complexity
export const postUserLoginSaga = function* (
	action: ISagaAction<IGigyaOnLogin>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.Auth.SSO,
			action.payload
		);
		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			yield put(actions.userLoginFailed());
			clearUserFromStorage();
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		if (GAME_NAME === "Tabcorp" && window.snaptr) {
			window.snaptr("track", "SIGN_UP");
		}

		if (GAME_NAME === "Tabcorp" && window.fbq) {
			window.fbq("track", "Lead");
		}

		saveUserToStorage(result.user, result.session_id);
		yield put(actions.userLoginSuccess(result.user));
	} catch (e) {
		Sentry.withScope((scope) => {
			scope.setExtra("action", action);
			scope.setExtra("reasons", e.message);
			scope.setExtra("error", e);
			Sentry.captureException(e);
		});
		const USER_NOT_REGISTERED = 404;
		if (e.code === USER_NOT_REGISTERED) {
			return yield put(actions.additionalInformationRequired());
		}

		yield put(actions.errorsGlobalError(e));
	}
};

export const registrationUserSaga = function* (
	action: ISagaAction<IUserCreate>
): SagaIterator {
	try {
		const {result, ...status} = yield call(
			Api.Auth.registration,
			action.payload
		);

		errorsCheck(status);

		const user = result.user;

		saveUserToStorage(result.user, result.sid);

		if (["Tabcorp", "Mexico"].includes(GAME_NAME) && window.fbq) {
			window.fbq("track", "CompleteRegistration");
		}

		yield put(actions.userRegistrationSuccess(user));
	} catch (err) {
		const FORBID_CODE = 403;
		const message =
			err.code === FORBID_CODE
				? window.getTranslations("registration_forbidden_country")
				: err.message;

		if (err.code !== FORBID_CODE) {
			Sentry.withScope((scope) => {
				scope.setExtra("action", action);
				scope.setExtra("reasons", err.message);
				scope.setExtra("error", err);
				Sentry.captureException(err);
			});
		}

		yield put(actions.errorsGlobalError({...err, message}));
		yield put(actions.userRegistrationFailed());
	}
};

export const registerForGameSaga = function* (
	action: ISagaAction<IUserRegisterForGame>
): SagaIterator {
	try {
		const {result, ...status} = yield call(
			Api.Auth.register_for_game,
			action.payload
		);

		errorsCheck(status);

		const user = result.user;

		saveUserToStorage(result.user, result.sid);

		if (["Tabcorp", "Mexico"].includes(GAME_NAME) && window.fbq) {
			window.fbq("track", "CompleteRegistration");
		}

		yield put(actions.userRegistrationSuccess(user));
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
		yield put(actions.userRegistrationFailed());
	}
};

export const returningRegisterForGameSaga = function* (
	action: ISagaAction<IUserRegisterForGame>
): SagaIterator {
	try {
		const {result, ...status} = yield call(
			Api.User.register_for_game,
			action.payload
		);

		errorsCheck(status);

		const user = result.user;

		saveUserToStorage(result.user, result.sid);

		if (["Tabcorp", "Mexico"].includes(GAME_NAME) && window.fbq) {
			window.fbq("track", "CompleteRegistration");
		}

		yield put(actions.userRegistrationSuccess(user));
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
		yield put(actions.userReturningRegisterForGameFailed(err));
	}
};

export const checkUsernameSaga = function* (
	action: ISagaAction<string>
): SagaIterator {
	try {
		const response = yield call(Api.User.check_username, {
			username: action.payload,
		});
		yield call(responseCheck, response);
		yield put(actions.checkUsernameSuccess());
	} catch (err) {
		// const NOT_EXTENDED = 510;
		// const EXIST = 403;
		console.log(err);
		// const message = err.code === NOT_EXTENDED || err.code === EXIST ?
		// 	window.getTranslations('register_form_username_already_exists') :
		// 	err.message;

		const message = err.message;
		yield put(actions.checkUsernameFailed({...err, message}));
	}
};

export const checkEmailSaga = function* (
	action: ISagaAction<string>
): SagaIterator {
	try {
		const response = yield call(Api.User.check_email, {
			email: action.payload,
		});
		yield call(responseCheck, response);
		yield put(actions.checkEmailSuccess());
	} catch (err) {
		// const REGISTERED_CODE = 514;

		// const message = err.code === REGISTERED_CODE ?
		// 	window.getTranslations('register_form_useremail_already_exists') :
		// 	err.message;

		const message = err.message;

		console.log(err);
		yield put(actions.checkEmailFailed({...err, message}));
	}
};

export const postUserLogoutSaga = function* (): SagaIterator {
	try {
		clearUserFromStorage();
		yield call(Api.Auth.logout);
		yield put(actions.userLogoutSuccess());
	} catch (e) {
		console.log(e);
	}
};

export const fetchUserSaga = function* (): SagaIterator {
	try {
		const response = yield call(Api.User.show_my);
		yield call(responseCheck, response);
		const {result, errors} = response;
		if (errors.length) {
			window.location.replace(`${process.env.REACT_APP_URL}`);
			yield put(actions.userLoginFailed());
		} else {
			saveUserToStorage(result);
			yield put(actions.userLoginSuccess(result));
		}
	} catch (err) {
		console.log(err);
		clearUserFromStorage();
		yield put(actions.userLoginFailed());
		throw new ApiErrors(err.message, err.code);
	}
};

export const acceptTheConditionsSaga = function* (): SagaIterator {
	try {
		const {result} = yield call(Api.User.update, {terms: 1});
		saveUserToStorage(result.user, result.session_id);
		yield put(actions.acceptTheConditionsSuccess(result.user));
	} catch (e) {
		console.log(e);
	}
};

export const showMyProfileSaga = function* (): SagaIterator {
	try {
		const response = yield call(Api.User.show_my_profile);
		yield call(responseCheck, response);
		const {result} = response;
		saveUserToStorage(result);
		yield put(actions.showMyProfileSuccess(result));
	} catch (err) {
		console.log(err);
	}
};

export const updateUserSaga = function* (
	action: ISagaAction<IUserUpdate>
): SagaIterator {
	try {
		const response = yield call(Api.User.update, action.payload);
		yield call(responseCheck, response);
		const {result} = response;
		const team_supported = _.get(action.payload, "team_supported");

		saveUserToStorage(result.user, result.session_id);

		yield put(
			actions.updateUserSuccess({
				user: result.user,
				team_supported: team_supported.toString(),
			})
		);
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
	}
};

export const deactivateUserSaga = function* (): SagaIterator {
	try {
		const response = yield call(Api.User.deactivate);
		yield call(responseCheck, response);
		const {result} = response;

		console.log(result);

		yield call(postUserLogoutSaga);
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
	}
};

export const disableTooltipSaga = function* (): SagaIterator {
	try {
		const is_logged_in = yield select(isLoggedIn);

		if (!is_logged_in) {
			yield put(actions.hideTooltip());
			return;
		}

		const {result} = yield call(Api.User.update, {disable_tooltip: 1});

		saveUserToStorage(result.user, result.session_id);
		yield put(actions.disableTooltipSuccess(result.user));
	} catch (e) {
		console.log(e);
	}
};

export const enableTooltipSaga = function* (): SagaIterator {
	try {
		const is_logged_in = yield select(isLoggedIn);

		if (!is_logged_in) {
			yield put(actions.showTooltip());
			return;
		}

		const {result} = yield call(Api.User.update, {disable_tooltip: 0});

		saveUserToStorage(result.user, result.session_id);
		yield put(actions.enableTooltipSuccess(result.user));
	} catch (e) {
		console.log(e);
	}
};

export const userUnsubscribeSaga = function* (
	action: ISagaAction<IUserUpdate>
): SagaIterator {
	try {
		const response = yield call(Api.User.unsubscribe, action.payload);
		yield call(responseCheck, response);
		const {result} = response;
		console.log(result);
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
	}
};
