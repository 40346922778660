import * as _ from "lodash";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import {
	IAutopickMyTips,
	ISagaAction,
	ISaveMyTips,
	IShowMyTips,
	ITip,
} from "../../types";
import {Api, ApiErrors, PICKS_STORAGE_KEY} from "../../utils";
import * as actions from "../../actions";
import {getTipsData, isLoggedIn} from "../../selectors";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../../utils/Сonnextra/connextra";
import {createFanHubEvent} from "../../utils/FanHubTagging";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

const saveUnSavedPicks = function* (unSavedTips: ISaveMyTips[]) {
	try {
		localStorage.removeItem(PICKS_STORAGE_KEY);

		const {result, errors, ...status} = yield call(Api.Tips.save_list, {
			tips: unSavedTips,
		});

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		if (GAME_NAME === "Tabcorp" && window.snaptr) {
			window.snaptr("track", "SAVE");
		}

		yield put(actions.showMyTipsSuccess(result));
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const showMyTipsSaga = function* (
	action: ISagaAction<IShowMyTips>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.Tips.show_my_list,
			action.payload
		);
		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		const unSavedTips = JSON.parse(
			localStorage.getItem(PICKS_STORAGE_KEY) || "[]"
		) as ISaveMyTips[];
		const userHasSavedTips = (result as ITip[]).some(
			(tip) => tip.predict_winner_squad_id
		);

		if (userHasSavedTips && unSavedTips.length) {
			yield call(saveUnSavedPicks, unSavedTips);
		} else {
			yield put(actions.showMyTipsSuccess(result));
		}
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

const updateTipsData = (
	tipsData: ITip[],
	newPrediction: ISaveMyTips
): ITip[] => {
	return [
		...tipsData.filter(({id}) => id !== newPrediction.match_id),
		{
			id: newPrediction.match_id,
			predict_winner_squad_id: newPrediction.predict_winner_squad_id,
			is_draw: 0,
			is_correct: 0,
			is_autogenerate: 0,
			hidden: 0,
			predict_margin: newPrediction.predict_margin || 0,
		},
	];
};

const convertTipsData = (tips: ISaveMyTips[]): ITip[] => {
	return tips.map((tip) => ({
		id: tip.match_id,
		predict_winner_squad_id: tip.predict_winner_squad_id,
		is_draw: 0,
		is_correct: 0,
		is_autogenerate: 0,
		hidden: 0,
		predict_margin: tip.predict_margin || 0,
	}));
};

export const saveMyTipsSaga = function* (
	action: ISagaAction<ISaveMyTips>
): SagaIterator {
	try {
		const is_logged_in = yield select(isLoggedIn);

		if (is_logged_in) {
			const {result, errors, ...status} = yield call(
				Api.Tips.save,
				action.payload
			);

			const is_error = !_.isEmpty(errors);

			if (is_error && !status.success) {
				throw new ApiErrors(errors[0].text, errors[0].code);
			}

			if (GAME_NAME === "Tabcorp" && window.snaptr) {
				window.snaptr("track", "SAVE");
			}

			createFanHubEvent("a90568de-5614-4437-84f5-2c70886c35cf");

			yield put(actions.showMyTipsSuccess(result));
			yield put(actions.saveMyTipsSuccess());
		} else {
			console.log("Update tips data");

			const tipsData = yield select(getTipsData);
			yield put(
				actions.showMyTipsSuccess(
					updateTipsData(tipsData, action.payload)
				)
			);
		}
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const saveMyListTipsSaga = function* (
	action: ISagaAction<ISaveMyTips[]>
): SagaIterator {
	try {
		const is_logged_in = yield select(isLoggedIn);

		if (is_logged_in) {
			const {result, errors, ...status} = yield call(Api.Tips.save_list, {
				tips: action.payload,
			});

			const is_error = !_.isEmpty(errors);

			if (is_error && !status.success) {
				throw new ApiErrors(errors[0].text, errors[0].code);
			}

			if (GAME_NAME === "Tabcorp" && window.snaptr) {
				window.snaptr("track", "SAVE");
			}

			yield put(actions.showMyTipsSuccess(result));
			yield put(actions.saveMyListTipsSuccess());
		} else {
			localStorage.setItem(
				PICKS_STORAGE_KEY,
				JSON.stringify(action.payload)
			);

			createConnextraScriptTag(ConnextraType.LOGIN);

			const gigyaWrapperInstance = yield select(
				(state) => state.user.gigyaWrapperInstance
			);

			gigyaWrapperInstance.gigya.sso.login({
				authFlow: "redirect",
			});
		}
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const autopickMyTipsSaga = function* (
	action: ISagaAction<IAutopickMyTips>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.Tips.autopick,
			action.payload
		);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		if (GAME_NAME === "Tabcorp" && window.snaptr) {
			window.snaptr("track", "SAVE");
		}

		yield put(actions.showMyTipsSuccess(result));
		yield put(actions.autopickMyTipsSuccess());
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const freeAutopickMyTipsSaga = function* (
	action: ISagaAction<IAutopickMyTips>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.Tips.free_autopick,
			action.payload
		);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		yield put(actions.selectTips(result));
		yield put(actions.showMyTipsSuccess(convertTipsData(result)));
		yield put(actions.freeAutopickMyTipsSuccess());
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};
