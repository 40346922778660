import {IShowBanner} from "./../../reducers/show_banner/index";
import {createAction} from "redux-act";
import {IGameWeek} from "../../reducers/gameweeks";
import {ISquad} from "../../reducers/squads";
import {IDivisionalStandings} from "../../reducers/divisional_standings";
import {ITranslations} from "../../reducers/jsons";

export const fetchGameWeeksJSON = createAction();
export const fetchGameWeeksJSONSuccess = createAction<IGameWeek[]>();
export const fetchGameWeeksJSONFailed = createAction<Error>();

export const fetchSquadsJSON = createAction();
export const fetchSquadsJSONSuccess = createAction<ISquad[]>();
export const fetchSquadsJSONFailed = createAction<Error>();

export const fetchStandingsJSONS = createAction("Fetch Standings JSONS");
export const fetchStandingsJSONSuccess = createAction<IDivisionalStandings[]>(
	"Fetch Standings JSON Success"
);

export const fetchTranslationsJSON = createAction<{lang: string}>();
export const fetchTranslationsJSONSuccess = createAction<ITranslations>();

export const fetchBannerJSON = createAction();
export const fetchBannerJSONSuccess = createAction<IShowBanner>();
export const fetchBannerJSONFailed = createAction<Error>();
