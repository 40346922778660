import * as React from "react";
import {Content, RulesLink, withHeader} from "../components";
import styled from "styled-components";
import {connect} from "react-redux";
import {fetchPrizes} from "../modules/actions";
import {IPrizes, IStoreState} from "../modules/types";
import {getPrizes} from "../modules/selectors/jsons";
import {getIVWTracking} from "../modules/selectors";
import {filter, first, map, size} from "lodash";
import prizes_background from "../assets/img/prizes-header.jpg";
import prizes_background_mobile from "../assets/img/prizes-header-mobile.jpg";
import header_bg_uk from "../assets/img/header_bg_prize_uk.jpg";
import prize_icon from "../assets/img/icons/prize.svg";
import theme from "../assets/css/theme";
import {below} from "../assets/css/media";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

const PrizesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;

	${below.desktop`
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 20px;
	`};
`;

const Prize = styled.div`
	max-width: 600px;
	min-width: 450px;
	width: 100%;
	border-radius: 4px;
	border-top: 1px solid #e5e5e5;
	border-left: 1px solid #e5e5e5;
	border-right: 1px solid #e5e5e5;
	border-bottom: 10px solid #1b48e0;
	box-sizing: border-box;
	margin-bottom: 30px;
	margin-right: 2%;
	margin-left: 2%;
	padding-bottom: 30px;
	background-color: #fff;
	height: 100%;

	${below.desktop`
		min-width: 90%;
		max-width: 90%;
		width: 90%;
	`};
`;

interface IHeader {
	bg: string | null;
}

const Header = styled.div<IHeader>`
	//height: 210px;
	display: flex;
	width: 100%;
	align-items: flex-end;
	background: ${({bg}) => bg && `url(${bg}) center top no-repeat`};
	background-size: cover;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
`;

const HeaderTitle = styled.div`
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	font-family: ${theme.base_font_medium_display};
	height: 60px;
	text-transform: uppercase;
	color: #fff;
	font-size: 28px;
	padding: 20px;
	& span {
		display: inline-block;
		width: 50%;
	}
`;

const PrizesContent = styled.div`
	padding: 30px 20px;
	height: 100%;

	p,
	div,
	figure td {
		color: #555;
		font-size: 14px;
		line-height: 22px;
		padding-bottom: 10px;
	}

	figure td a {
		text-decoration: underline;
	}

	img {
		display: none;
	}

	h3 {
		color: #333;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -0.1px;
		font-family: ${theme.base_bold_font};
		margin-bottom: 20px;
	}

	dl {
		background-image: url("${prize_icon}");
		background-repeat: no-repeat;
		background-position: center left;
		background-size: contain;
		padding-left: 50px;
		height: 44px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		margin: 15px 0;

		dt {
			font-size: 14px;
			line-height: 18px;
			color: #333;
			font-family: ${theme.base_bold_font};
		}

		dd {
			color: #757575;
			font-family: ${theme.base_font};
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.1px;
		}

		strong {
			font-family: ${theme.base_bold_font};
		}
	}
`;

const Terms = styled.span`
	margin: 0 20px;
	padding: 3px 0;
	color: ${theme.placeholder};
	font-size: 14px;
	line-height: 22px;
	border-bottom: 1px solid ${theme.placeholder};
	cursor: pointer;
`;

interface IProps {
	prizes: IPrizes[];
	fetchPrizes: typeof fetchPrizes;
	readonly ivw_tracking: any;
}

export class PrizesComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {fetchPrizes: fetch, ivw_tracking} = this.props;

		if (GAME_NAME === "prosieben") {
			ivw_tracking("/us-sport/nfl/nfltippspiel/gewinne");

			window.ga("send", {
				hitType: "pageview",
				page: "/us-sport/nfl/tippspiel/gewinne",
			});
		}
		fetch();
	}

	public parseImgSrc(prize_content: string) {
		const prize_dom = new DOMParser().parseFromString(
			prize_content,
			"text/html"
		);
		const image = first(prize_dom.getElementsByTagName("img"));

		if (image) {
			return image.getAttribute("src");
		}

		return "";
	}

	public render() {
		// if (GAME_NAME === "Mexico") {
		// 	return <Redirect to="/" />;
		// }
		const {prizes} = this.props;
		const filtered_prizes = filter(
			prizes,
			(item) => item.tags !== "sidebar"
		);

		return (
			<Content
				header_background={
					GAME_NAME === "UK" ? header_bg_uk : prizes_background
				}
				header_background_mobile={
					GAME_NAME === "UK" ? header_bg_uk : prizes_background_mobile
				}
				title={window.getTranslations("pickem_nav_button_prizes")}
			>
				<PrizesWrapper>
					{size(filtered_prizes)
						? map(filtered_prizes, (prize, key) => {
								const prize_image = this.parseImgSrc(
									prize.text
								);
								const prizeTitle = prize.title.replace(
									/&amp;/g,
									"&"
								);
								return (
									<Prize key={key}>
										<Header bg={prize_image}>
											<HeaderTitle>
												<span>{prizeTitle}</span>
											</HeaderTitle>
										</Header>
										<PrizesContent
											dangerouslySetInnerHTML={{
												__html: prize.text,
											}}
										/>
										<RulesLink>
											<Terms>
												{window.getTranslations(
													"register_form_terms_conditions_apply"
												)}
											</Terms>
										</RulesLink>
									</Prize>
								);
						  })
						: null}
				</PrizesWrapper>
			</Content>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	prizes: getPrizes(state),
	ivw_tracking: getIVWTracking,
});

const mapDispatchToProps = {
	fetchPrizes,
};

export const Prizes = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(PrizesComponent)
);
export default Prizes;
