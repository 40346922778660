import * as React from "react";
import styled from "styled-components";
import {below} from "../../assets/css/media";
import {showMyTips, fetchRoundStats} from "../../modules/actions";
import {IGameWeek} from "../../modules/reducers";
import {map} from "lodash";
import {IUserTips} from "../../modules/types";
import {TooltipBubbleMobile} from "./../../components/TooltipBubble/TooltipBubbleMobile";

import TipItem from "./tipItem";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

const Form = styled.form`
	position: relative;
`;

const SkipTutorialDiv = styled.div`
	background: transparent;
	border: none;
	color: #fff;
	cursor: pointer;
	font-size: 20px;
	margin-top: 400px;
	position: absolute;
	text-align: center;
	text-decoration: underline;
	width: 100%;
	z-index: 2;
	${below.desktop`
		display:none;
	`};
`;

interface IProps {
	handleDisableTooltip: () => void;
	showMyTips: typeof showMyTips;
	fetchStats: typeof fetchRoundStats;
	onChangeTip: (event: React.FormEvent<HTMLFormElement>) => void;
	readonly actual_gameweek: IGameWeek;
	readonly tips: IUserTips[];
	readonly not_select_tips: boolean;
	show_tooltips: boolean;
	is_logged_in: boolean;
}

export class MyTips extends React.Component<IProps> {
	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {
			showMyTips: showTips,
			fetchStats: fetchStatsPiks,
			actual_gameweek,
			is_logged_in,
		} = this.props;

		fetchStatsPiks({
			round: actual_gameweek.id,
		});

		createFanHubEvent("ff8a2ae0-41ec-4196-bbe5-272daae95842");

		if (is_logged_in) {
			showTips({
				round: actual_gameweek.id,
			});
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			tips,
			onChangeTip,
			show_tooltips,
			not_select_tips,
			handleDisableTooltip,
		} = this.props;

		return (
			<React.Fragment>
				{show_tooltips && (
					<TooltipBubbleMobile onClose={handleDisableTooltip} />
				)}
				<Form onChange={onChangeTip}>
					{map(tips, (tip, index) => (
						<React.Fragment key={tip.id}>
							<TipItem
								tip={tip}
								index={index}
								is_first={index === 0}
								show_tooltips={show_tooltips}
								not_select_tips={not_select_tips}
							/>
							{show_tooltips && index === 0 && (
								<SkipTutorialDiv onClick={handleDisableTooltip}>
									Skip this tutorial
								</SkipTutorialDiv>
							)}
						</React.Fragment>
					))}
				</Form>
			</React.Fragment>
		);
	}
}

export default MyTips;
