import * as React from "react";
import {connect} from "react-redux";
import {IStoreState} from "../../modules/types";
import LoadingSpinner from "../AppLoading";
import styled from "styled-components";
import {get, size, find} from "lodash";
import {fetchTranslationsJSON} from "../../modules/actions";
import {ITranslations} from "../../modules/reducers/jsons";
import {RouteComponentProps, withRouter} from "react-router-dom";

const PreloaderContainer = styled.div`
	width: 100%;
	height: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LANG_KEY = process.env.REACT_APP_LANG_KEY || "";

interface IProps extends RouteComponentProps {
	children: React.ReactNode;
	fetchTranslationsJSON: typeof fetchTranslationsJSON;
	readonly translations: ITranslations;
}

interface IDynamicParams {
	[key: string]: string;
}

class TranslationsDataComponent extends React.Component<IProps> {
	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		this.props.fetchTranslationsJSON({
			lang: LANG_KEY,
		});
	}

	/**
	 * @ignore
	 */
	public render(): React.ReactNode {
		const {children, translations} = this.props;

		if (size(translations)) {
			this.setGlobalTranslationsData();
		}

		return size(translations) ? (
			children
		) : (
			<PreloaderContainer>
				<LoadingSpinner />
			</PreloaderContainer>
		);
	}

	private setGlobalTranslationsData() {
		const {translations} = this.props;
		window.translations = translations;
		window.getTranslations = this.getTranslate;

		const isShowAllKeys =
			new URLSearchParams(this.props.location.search).get("cimode") ===
			"true";
		window.LANG_KEY = isShowAllKeys ? "cimode" : LANG_KEY;
	}

	private getTranslate(
		key_t: string = "",
		dynamic_params?: IDynamicParams[]
	) {
		if (window.LANG_KEY === "cimode") {
			return key_t;
		}

		const translation = get(window.translations, key_t, key_t);

		if (!dynamic_params) {
			return translation;
		}

		return translation.replace(/<(\w+)>/g, (_: any, k: string) => {
			return get(
				find(dynamic_params, (param) => param[k] || false),
				k
			);
		});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	translations: get(state, "jsons.translations"),
});

const mapDispatchToProps = {
	fetchTranslationsJSON,
};

export const TranslationsData = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TranslationsDataComponent)
);

export default TranslationsData;
