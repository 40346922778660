import * as React from "react";
import {Content, withHeader} from "../../components";
import styled, {css} from "styled-components";
import theme from "../../assets/css/theme";
import {connect} from "react-redux";
import {clearLeagueFlags} from "../../modules/actions";
import {IModelUser, IStoreState} from "../../modules/types";
import {get} from "lodash";
import {below} from "../../assets/css/media";
import InvitesLeague from "../league/leagueAbout/invitesLeague";
import LeagueAbout from "../league/leagueAbout/leagueAbout";
import CreateLeagueForm from "./createLeague/createLeagueForm";
import {getOmnitureTracking} from "../../modules/selectors";
import header_bg from "../../assets/img/header_bg.png";
import header_bg_mobile from "../../assets/img/header_bg_mobile.png";
import header_bg_uk from "../../assets/img/header_bg_league_uk.jpg";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

const PageWrapper = styled.div`
	max-width: 1240px;
	width: 100%;
	display: flex;
`;

const StepIndicator = styled.div`
	display: flex;
	align-items: center;
	color: #1b48e0;
	font-size: 12px;
	letter-spacing: 0.1px;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-family: ${theme.base_font};
	span {
		min-width: 190px;
	}

	&:after {
		content: "";
		width: 100%;
		border-top: 1px solid #1b48e0;
	}
`;

interface ISection {
	flex: string;
	active: boolean;
}

const Section = styled.div<ISection>`
	flex: ${({flex}) => flex};
	min-width: 380px;
	margin: 20px;
	${({active}) =>
		!active &&
		css`
			${StepIndicator}, :last-child {
				filter: grayscale(1);
				opacity: 0.5;
				cursor: not-allowed;
				> * {
					pointer-events: none;
				}
			}

			${below.desktop`
			display:none;
		`};
		`};

	${below.desktop`
		min-width: 90%;
	`};
`;

interface IProps {
	clearLeagueFlags: typeof clearLeagueFlags;
	readonly league_created_id: number | null;
	readonly omniture_tracking: any;
	user: IModelUser;
}

class CreateLeagueComponent extends React.Component<IProps> {
	public get invite_league() {
		const {league_created_id, clearLeagueFlags: clearFlags} = this.props;
		return (
			<React.Fragment>
				<StepIndicator>
					{window.getTranslations("create_league_form_header_step2")}
				</StepIndicator>

				<InvitesLeague
					clear={clearFlags}
					league_created_id={league_created_id}
				/>
			</React.Fragment>
		);
	}

	public get create_league_content() {
		return <CreateLeagueForm />;
	}

	public get create_league() {
		const {league_created_id} = this.props;

		return (
			<React.Fragment>
				<StepIndicator>
					<span>
						{window.getTranslations("create_league_form_header_step1")}
					</span>
				</StepIndicator>

				{league_created_id ? (
					<LeagueAbout
						shadow={true}
						league_created_id={league_created_id}
					/>
				) : (
					this.create_league_content
				)}
			</React.Fragment>
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {omniture_tracking, user} = this.props;
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				!this.is_league_created
					? omniture_tracking(
							"leagues",
							"create a league",
							"step 1: create your league",
							user
					  )
					: omniture_tracking(
							"leagues",
							"create a league",
							"step 2: invite your friends",
							user
					  )
			);
		}
		return (
			<Content
				title={window.getTranslations("mycomps_header_button_create")}
				header_background={
					GAME_NAME === "UK" ? header_bg_uk : header_bg
				}
				header_background_mobile={
					GAME_NAME === "UK" ? header_bg_uk : header_bg_mobile
				}
			>
				<PageWrapper>
					<Section active={!this.is_league_created} flex="1">
						{this.create_league}
					</Section>

					<Section active={this.is_league_created} flex="2">
						{this.invite_league}
					</Section>
				</PageWrapper>
			</Content>
		);
	}

	private get is_league_created() {
		const {league_created_id} = this.props;

		return !!league_created_id;
	}
}

const mapStateToProps = (state: IStoreState) => ({
	league_created_id: get(state, "league.league_created_id"),
	omniture_tracking: getOmnitureTracking,
	user: state.user,
});

const mapDispatchToProps = {
	clearLeagueFlags,
};

export const CreateLeague = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(CreateLeagueComponent)
);

export default CreateLeague;
