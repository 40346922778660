import * as React from "react";
import {
	ContentWithSidebar,
	Exist,
	LoadingSpinner,
	MultiOddsGenerator,
	MultiOddsGeneratorUK,
	Overlay,
	withHeader,
} from "../components";
import {
	IModelUser,
	ISaveMyTips,
	IStoreState,
	IUserTips,
} from "../modules/types";
import {ISnapshot} from "modules/reducers/tipping_stats";
import {connect} from "react-redux";
import {bindAll, countBy, filter, findIndex, get} from "lodash";
import {RouteComponentProps} from "react-router-dom";

import {
	autopickMyTips,
	clearSelectedTip,
	disableTooltip,
	enableTooltip,
	fetchBannerJSON,
	fetchGameWeeksJSON,
	fetchRoundStats,
	fetchSnapshot,
	fetchSquadsJSON,
	freeAutopickMyTips,
	hideTooltip,
	saveMyListTips,
	saveMyTips,
	selectTip,
	selectTips,
	setSelectedTip,
	showMyTips,
	showTooltip,
	subscribeToLiveScores,
	unsubscribeFromLiveScores,
} from "../modules/actions";

import {
	getActualGameWeek,
	getActualGameWeeks,
	getFirstGameWeek,
	getGameWeekById,
	getGameWeeks,
	getIsSeasonStarted,
	getIVWTracking,
	getOmnitureTracking,
	getSaveStatus,
	getSelectedTips,
	getUserTips,
	isLoggedIn,
	isNeedRegisterForGame,
} from "../modules/selectors";
import {IGameWeek} from "../modules/reducers";

import MyTips from "./PickEm/myTips";
import Sidebar from "./PickEm/sidebar";
import DivisionalStandingsWidget from "./PickEm/divisionalStandingsWidget";
import ShareButton from "../components/Buttons/ShareButton";
import CrossPromoPopup from "components/CrossPromoPopup";
import {getShowBanner} from "modules/selectors/show_banner";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../modules/utils/Сonnextra/connextra";
import {IS_AU, IS_RTL} from "modules/utils";
import {Redirect} from "react-router";
import RegularButton from "../components/Buttons/RegularButton";
import styled from "styled-components";

const SavePicksButton = styled(RegularButton)`
	max-width: 220px;
	height: 40px;
	padding: 0 20px;
	margin: 0 auto;
	position: relative;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

interface IState {
	subscribed_to_live_scores: boolean;
	hasFinishedTippingRound: boolean;
	showCrossPromotion: boolean;
}

interface IRouteParams {
	gw_id?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
	disableTooltip: typeof disableTooltip;
	enableTooltip: typeof enableTooltip;
	fetchBannerJSON: typeof fetchBannerJSON;
	fetchGameWeeksJSON: typeof fetchGameWeeksJSON;
	showMyTips: typeof showMyTips;
	saveMyTips: typeof saveMyTips;
	saveMyListTips: typeof saveMyListTips;
	autopickMyTips: typeof autopickMyTips;
	freeAutopickMyTips: typeof freeAutopickMyTips;
	fetchSnapshot: typeof fetchSnapshot;
	fetchSquadsJSON: typeof fetchSquadsJSON;
	setSelectedTip: typeof setSelectedTip;
	clearSelectedTip: typeof clearSelectedTip;
	fetchRoundStats: typeof fetchRoundStats;
	subscribeToLiveScores: typeof subscribeToLiveScores;
	unsubscribeFromLiveScores: typeof unsubscribeFromLiveScores;
	selectTip: typeof selectTip;
	selectTips: typeof selectTips;
	showTooltip: typeof showTooltip;
	hideTooltip: typeof hideTooltip;
	selected_tips: ISaveMyTips[];
	readonly actual_gameweek?: IGameWeek;
	readonly current_gameweek?: IGameWeek;
	readonly gameweeks: IGameWeek[];
	readonly all_gameweeks: IGameWeek[];
	readonly tips: IUserTips[] | [];
	readonly snapshot: ISnapshot;
	readonly omniture_tracking: any;
	readonly ivw_tracking: any;
	readonly first_round?: IGameWeek;
	user: IModelUser;
	tips_saved: boolean;
	is_logged_in: boolean;
	is_need_register_for_game: boolean;
	is_season_started: boolean;
}

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
const shareButtonText = (actual_gameweek: IGameWeek) =>
	actual_gameweek.status === "complete" ? "results" : "predictions";
const shareText = (actual_gameweek: IGameWeek) =>
	window.getTranslations(
		actual_gameweek.status === "complete"
			? "pickem_form_button_share_results"
			: "pickem_form_button_share_picks"
	);

const saveButtonText = (tips_saved: boolean) =>
	tips_saved
		? window.getTranslations("pickem_gamebar_button_saved") || ""
		: window.getTranslations("pickem_gamebar_button_save") || "";

export class PickEmComponent extends React.Component<IProps, IState> {
	public state = {
		subscribed_to_live_scores: false,
		hasFinishedTippingRound: false,
		showCrossPromotion: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, [
			"handleTipSave",
			"handleAutopick",
			"changeGameWeek",
			"handleChange",
			"resetSelected",
			"handleDisableTooltip",
			"handleEnableTooltip",
			"handleCloseCrossPromotion",
		]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {
			actual_gameweek,
			fetchBannerJSON: fetchBanner,
			fetchGameWeeksJSON: fetchGameWeeks,
			fetchSnapshot: showSnapshot,
			fetchSquadsJSON: fetchSquads,
			ivw_tracking,
			user,
			is_logged_in,
			showTooltip: openTooltip,
		} = this.props;

		fetchBanner();
		fetchGameWeeks();
		fetchSquads();

		if (is_logged_in) {
			showSnapshot();
		}

		if (!is_logged_in) {
			openTooltip();
		}

		const weekNumber =
			actual_gameweek &&
			`${window.getTranslations(
				"competitionjoin_list_placeholder_week"
			)} ${actual_gameweek.id}`;
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				this.props.omniture_tracking(
					"pick em",
					weekNumber,
					"landing",
					user
				)
			);
		}

		if (GAME_NAME === "prosieben") {
			ivw_tracking("/us-sport/nfl/nfltippspiel/tippspiel");

			window.ga("send", {
				hitType: "pageview",
				page: "/us-sport/nfl/tippspiel",
			});
		}
		createConnextraScriptTag(ConnextraType.PICKEM_PAGE, user);
		this.subscribeToLiveUpdates();
	}

	/**
	 * @ignore
	 */
	public componentDidUpdate(): void {
		this.subscribeToLiveUpdates();
	}

	/**
	 * @ignore
	 */
	public shouldComponentUpdate(nextProps: IProps) {
		if (nextProps.tips_saved && !this.props.tips_saved) {
			this.checkTippedAll();
		}
		return true;
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			actual_gameweek,
			current_gameweek,
			showMyTips: showTips,
			tips,
			all_gameweeks,
			snapshot,
			fetchRoundStats: fetchStats,
			user,
			tips_saved,
			first_round,
			is_logged_in,
			is_need_register_for_game,
			is_season_started,
			selected_tips,
		} = this.props;

		if (is_need_register_for_game) {
			return <Redirect to="/returning-user" />;
		}

		const selected_count = filter(
			tips,
			(item) => item.predict_winner_squad_id !== 0
		);
		const StandingsWidget = (
			<DivisionalStandingsWidget key={"divisional-standings-widget"} />
		);
		const show_tooltips = !user.disable_tooltip;

		if (!(actual_gameweek && current_gameweek && first_round && tips)) {
			return <LoadingSpinner />;
		}

		return (
			<React.Fragment>
				<Exist when={show_tooltips}>
					<Overlay gameName={GAME_NAME} />
				</Exist>
				<Exist when={this.show_cross_on_modal}>
					<React.Fragment>
						<Overlay onClick={this.handleCloseCrossPromotion} />
						<CrossPromoPopup
							handleClose={this.handleCloseCrossPromotion}
							isSavePicks={true}
						/>
					</React.Fragment>
				</Exist>

				<Sidebar
					onSave={this.handleTipSave}
					onAutopick={this.handleAutopick}
					onClickInfo={this.handleEnableTooltip}
					actual_gameweek={actual_gameweek}
					current_gameweek={current_gameweek}
					changeGameWeek={this.changeGameWeek}
					gameweeks={all_gameweeks}
					snapshot={snapshot}
					tips={tips}
					is_selected_tips={Boolean(selected_tips.length)}
					tips_saved={tips_saved}
					first_round={first_round}
					is_logged_in={is_logged_in}
					is_season_started={is_season_started}
				/>

				<ContentWithSidebar sidebar_components={[StandingsWidget]}>
					<MyTips
						actual_gameweek={actual_gameweek}
						handleDisableTooltip={this.handleDisableTooltip}
						tips={tips}
						showMyTips={showTips}
						is_logged_in={is_logged_in}
						fetchStats={fetchStats}
						onChangeTip={this.handleChange}
						show_tooltips={show_tooltips}
						not_select_tips={tips.length !== selected_count.length}
					/>

					<Exist when={is_logged_in}>
						<ShareButton
							disabled={tips.length !== selected_count.length}
							actual_gameweek={actual_gameweek.id}
							section={shareButtonText(actual_gameweek)}
							actual_gameweek_status={actual_gameweek.status}
							score={this.tips_points}
						>
							{shareText(actual_gameweek)}
						</ShareButton>
					</Exist>

					<Exist when={!is_logged_in}>
						<SavePicksButton
							disabled={this.isDisabledSaveButton}
							onClick={this.handleTipSave}
						>
							{saveButtonText(tips_saved)}
						</SavePicksButton>
					</Exist>

					<MultiOddsGenerator tips={tips} />
					<Exist when={GAME_NAME === "UK"}>
						<MultiOddsGeneratorUK tips={tips} />
					</Exist>
				</ContentWithSidebar>
			</React.Fragment>
		);
	}

	private get show_cross_on_modal() {
		const {hasFinishedTippingRound, showCrossPromotion} = this.state;
		return (
			process.env.REACT_APP_NFL_FANTASY_URL !== "" &&
			!IS_AU &&
			!IS_RTL &&
			showCrossPromotion &&
			hasFinishedTippingRound
		);
	}

	private changeGameWeek({
		currentTarget,
	}: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const {gw_id} = currentTarget.dataset;

		if (gw_id) {
			const gw_id_int = parseInt(gw_id, 0);
			const {showMyTips: showTips, fetchRoundStats: fetchStats} =
				this.props;

			this.props.history.push(`/pick-em/${gw_id_int}`);
			this.resetSelected();
			showTips({
				round: gw_id_int,
			});

			fetchStats({
				round: gw_id_int,
			});
		}
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value} = event.target as HTMLFormElement;
		const {user, selected_tips} = this.props;
		const find_tip = findIndex(
			selected_tips,
			(item) => get(item, "match_id") === +name
		);

		if (value !== 0) {
			if (find_tip !== -1) {
				selected_tips[find_tip] = {
					match_id: +name,
					predict_winner_squad_id: value,
				};
			} else {
				const new_tip = {
					match_id: +name,
					predict_winner_squad_id: value,
				};

				selected_tips.push(new_tip);
			}
			if (window._satellite) {
				window._satellite.track("savePicks");
			}
			createConnextraScriptTag(ConnextraType.PICKS_CONFIRM, user);
			// selectTips(selected_tips);
		}
	}

	private handleDisableTooltip() {
		this.props.disableTooltip();
	}

	private handleEnableTooltip() {
		this.props.enableTooltip();
		if (window._satellite) {
			window._satellite.track("helpModal");
		}
	}

	private handleCloseCrossPromotion() {
		this.setState({
			showCrossPromotion: false,
		});
	}

	private handleTipSave() {
		const {saveMyListTips: saveList, selected_tips} = this.props;

		if (selected_tips.length !== 0) {
			if (window._satellite) {
				window._satellite.track("savePicks");
			}
			saveList(selected_tips);
		}
		this.resetSelected();
	}

	private handleAutopick() {
		const {
			actual_gameweek,
			autopickMyTips: autopick,
			freeAutopickMyTips: freeAutopick,
			user,
			is_logged_in,
		} = this.props;
		if (window._satellite) {
			window._satellite.track("autoPick");
		}
		if (is_logged_in) {
			autopick({
				round: actual_gameweek?.id || 1,
				type: "luck_of_draw",
			});
			this.checkTippedAll();
			this.resetSelected();
		} else {
			freeAutopick({
				round: actual_gameweek?.id || 1,
				type: "luck_of_draw",
			});
		}

		createConnextraScriptTag(ConnextraType.AUTOPICK_CONFIRM, user);
	}

	private checkTippedAll() {
		const {actual_gameweek} = this.props;
		const {hasFinishedTippingRound} = this.state;
		const hasTippedAll = this.is_all_tipped;

		const seenPopupForRound =
			localStorage.getItem(
				`seenpopup-${GAME_NAME}-${actual_gameweek?.id || 1}`
			) !== null &&
			localStorage.getItem(
				`seenpopup-${GAME_NAME}-${actual_gameweek?.id || 1}`
			) === "1";
		if (hasFinishedTippingRound) {
			return;
		}
		if (hasTippedAll && !seenPopupForRound) {
			localStorage.setItem(
				`seenpopup-${GAME_NAME}-${actual_gameweek?.id || 1}`,
				"1"
			);
			this.setState({
				hasFinishedTippingRound: true,
				showCrossPromotion: true,
			});
		}
	}

	private resetSelected() {
		const {selectTips: selectUserTips} = this.props;
		selectUserTips([]);
	}

	get isDisabledSaveButton() {
		return this.props.tips_saved || !this.props.selected_tips.length;
	}

	private subscribeToLiveUpdates() {
		const {subscribed_to_live_scores} = this.state;

		const {
			subscribeToLiveScores: subscribe,
			unsubscribeFromLiveScores: unsubscribe,
			actual_gameweek,
		} = this.props;

		const status = get(actual_gameweek, "status");
		const is_active = status === "active";

		if (is_active && !subscribed_to_live_scores) {
			this.setState({subscribed_to_live_scores: true}, subscribe);
		}

		if (!is_active && subscribed_to_live_scores) {
			this.setState({subscribed_to_live_scores: false}, unsubscribe);
		}
	}

	get tips_points() {
		const {tips} = this.props;
		const points = countBy(tips, (item) => item.is_correct === 1);

		return points.true || 0;
	}

	get quantity_tips() {
		const {tips} = this.props;

		return tips.length;
	}

	get selected_quantity_tips() {
		const {tips} = this.props;
		const quantity_tips = countBy(
			tips,
			(item) => item.predict_winner_squad_id !== 0
		);

		return quantity_tips.true || 0;
	}

	get is_all_tipped() {
		return Boolean(
			this.quantity_tips === this.selected_quantity_tips &&
				this.selected_quantity_tips > 0
		);
	}
}

const mapStateToProps = (state: IStoreState, props: IProps) => {
	const {
		match: {
			params: {gw_id},
		},
	} = props;
	const int_gw_id = gw_id && parseInt(gw_id, 10);
	const actual_gameweek_state = getActualGameWeek(state);
	const MAX_GAME_WEEK_ID = get(actual_gameweek_state, "id", 1) + 1;
	const actual_gameweek =
		int_gw_id && int_gw_id <= MAX_GAME_WEEK_ID
			? getGameWeekById(state, int_gw_id)
			: getActualGameWeek(state);

	const tips = getUserTips(state, actual_gameweek);
	return {
		show_banner: getShowBanner(state),
		gameweeks: getActualGameWeeks(state),
		all_gameweeks: getGameWeeks(state),
		omniture_tracking: getOmnitureTracking,
		ivw_tracking: getIVWTracking,
		actual_gameweek,
		current_gameweek: getActualGameWeek(state),
		first_round: getFirstGameWeek(state),
		tips,
		snapshot: get(state, "snapshot"),
		user: get(state, "user"),
		tips_saved: getSaveStatus(state),
		is_logged_in: isLoggedIn(state),
		is_need_register_for_game: isNeedRegisterForGame(state),
		is_season_started: getIsSeasonStarted(state),
		selected_tips: getSelectedTips(state),
	};
};

const mapDispatchToProps = {
	fetchGameWeeksJSON,
	fetchBannerJSON,
	showMyTips,
	saveMyTips,
	saveMyListTips,
	autopickMyTips,
	freeAutopickMyTips,
	fetchSnapshot,
	fetchSquadsJSON,
	setSelectedTip,
	clearSelectedTip,
	fetchRoundStats,
	subscribeToLiveScores,
	unsubscribeFromLiveScores,
	disableTooltip,
	enableTooltip,
	showTooltip,
	selectTip,
	selectTips,
};

export const PickEm = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(PickEmComponent)
);

export default PickEm;
